/*
 * Colors-related Sass parameters
 */

$gray-100: #f6f6f6;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #eae9e9;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #626262;
$gray-9: #262626;

$zapier: #ff4a00;
$stripe: #635bff;

$gray-text: #545454;
$background: #f7f7f7;
$tool-text: #1f3153;
$white: #ffffff;
$tanuki-brown: #5a2a0a;
$primary-dark: #007296;
$primary-light-dark: #99c7d5;
$primary-neutral: #01a0c7;
$primary-extra-light: #e6f1f5;

$secondary-dark: #008b6d;
$secondary-light-dark: #99d1c5;
$secondary-neutral: #1ebc9b;
$secondary-extra-light: #e6f3f0;

$warnings-dark: #d34324;
$warnings-dark-darker: #a6351c;
$warnings-neutral: #f28123;
$warnings-light: #ffd75f;

$button-hover-background: #27aecf;
$button-hover-border: #1aaacd;
$text-muted: $gray-500;
$disabled-color: $gray-500;

$basic-text-content-color: $gray-800;
$basic-text-content-color-inv: $gray-100;
$basic-validity-true-color: #1abc9c;
$basic-validity-false-color: $warnings-dark;

$brand-light-color: #6fe2ff;
$brand-primary-color: $primary-neutral;
$brand-secondary-color: #183255;
$messenger-primary-color: #0084ff;

$public-container-background-color: white;
$private-container-background-color: white;

$public-sidebar-background-color: white;
$private-sidebar-background-color: white;

$public-navbar-background-color: white;
$private-navbar-background-color: white;

$link-color: $brand-primary-color;
$link-hover-color: $brand-secondary-color;
$input-placeholder-color: $text-muted;

$modal-backdrop-bg: #34495e;
$modal-backdrop-opacity: 0.8;

$general-background-color: white;

$page-container-background: #e5e5e5;

/*
* Channel Colors
*/
$bg-channel-web: $primary-neutral;
$bg-channel-google: rgba(216, 81, 64, 1);
$bg-channel-facebook: rgba(52, 116, 227, 1);
$bg-channel-instagram: #9d01e7;
$bg-channel-slack: #611f69;
$bg-channel-workplace: #4326c4;
$bg-channel-smooch: #30aabc;
$bg-channel-twilio: #f22f46;
$bg-channel-skype: #0078ca;
$bg-channel-businessmessages: #611f69;
$bg-channel-whatsapp: $secondary-neutral;
$bg-channel-teams: #4b53bc;
$bg-channel-api: #ffde31;

/*
* Plan Colors
*/
$free: $primary-extra-light;
$starter: $primary-light-dark;
$standard: $primary-neutral;
$premium: $primary-dark;
$enterprise: $secondary-neutral;

/*
 * Data types
 */
$object-color: $warnings-light;
$number-color: $warnings-neutral;
$string-color: $primary-neutral;
$boolean-color: $secondary-neutral;

/*
 * Overriding Bootstrap Theme
 */

$theme-colors: (
  'primary': $brand-primary-color,
  'secondary': $brand-secondary-color,
  'success': $basic-validity-true-color,
  'danger': $basic-validity-false-color,
  'light': $gray-200,
  'dark': $basic-text-content-color
);

:export {
  primaryNeutral: $primary-neutral;
}

.btn-secondary-dark {
  background-color: $secondary-dark;
  color: white;
}
