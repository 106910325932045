@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Demi.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: 700;
}
