@import 'variables/index';

.modal {
  overflow: hidden;
  transform: translateZ(0); // Fixes strange behavior with scrollbar z-index
}

.modal:not(:last-of-type) {
  z-index: 0;
  filter: blur(5px);
  transition: all 0.2s;
}
.modal-content {
  border-radius: 20px;
  backdrop-filter: blur(120px);
  background: rgba($color: $white, $alpha: 0.7);
}

.modal-dialog-full-size {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  margin: 0;

  .modal-content {
    height: calc(100% - 3rem);
    width: calc(100% - 3rem);
    margin: 0;
    max-width: 860px;
    max-height: 680px;
    border-radius: 0.3rem;
    overflow: hidden;

    .modal-body {
      flex-grow: 1;
      height: auto;
    }

    .modal-header,
    .modal-footer {
      border-radius: 0;
    }
  }
}

.modal-header {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  @include title-font-definition();
}

.modal-body {
  .window-content {
    min-height: 300px;
  }
}

.modal-footer {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  border-top: none;
}

.footer-error {
  position: relative;
  background-color: $basic-validity-false-color;
  color: $basic-text-content-color-inv;
  text-shadow: 1px 1px 3px rgba($basic-text-content-color, 0.5);
}

.footer-close {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 1rem;
  cursor: pointer;
  text-shadow: none;
}

.modal-transparent {
  .modal-content {
    background: transparent;
    border: unset;
  }
}

.modal-component {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 8px 32px rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(120px);
  border-radius: 20px;

  font-family: $content-font-family;
  font-style: normal;
  font-weight: normal;
  color: $gray-text;
}

// Spinner

.modal-spinner {
  .modal-content {
    text-align: center;
    background-color: transparent;
    backdrop-filter: unset;
    border: 0;
  }
}

body.modal-open {
  .pages-outlet,
  app-root,
  app-header {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
}

.modal-xxl {
  max-width: 95%;
  @include xxl-up {
    max-width: 1300px;
  }
}
