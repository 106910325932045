// PROFIDEO

.positive {
  color: #0c9170;
}

.negative {
  color: #e02020;
}

.ipc-title {
  background-color: #1c5886;
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 0.5rem !important;
  margin: -1rem -1rem 0 -1rem;
}

.tooltip-inner {
  background-color: $gray-600;
}

.tooltip .arrow {
  visibility: hidden;
}
