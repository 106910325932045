@import 'variables/index';
@import './libs.scss'; // for .btn class access

html {
  &:not(.livechat) {
    font-size: 16px;
  }
  &.livechat {
    @include fluid-type($screen-sm-min, $screen-xl-min, 9px, 13px);
  }
}

body {
  @include content-font-definition();
  color: $basic-text-content-color;
}

i {
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

button,
input[type='button'] {
  outline: 0 !important;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

input[type='number'].invalid {
  border-color: #e46664;
  box-shadow: 0 0 0 0.2rem rgb(228 102 100 / 25%);
}

.btn-no-decoration {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.btn-ideta {
  @extend .btn;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 6px 18px;

  &.disabled {
    background: $gray-6 !important;
    cursor: not-allowed;
  }
}

.media-upload {
  background-color: #01a0c7;
  color: #f6f6f6;
}

.btn-fake {
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  &.btn-link {
    color: inherit;
    padding: 0;
  }
}

.btn-fake,
.btn-no-decoration {
  &:disabled,
  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.3 !important;
  }
}

.btn-primary-dark {
  background: $primary-dark;
  color: #fff;
  border: none;
  &:hover {
    background: darken($color: $primary-dark, $amount: 10%);
    color: #fff;
  }
}

.btn-success {
  background: $secondary-dark;
}

a.linkified,
a.linkified:hover {
  color: $basic-text-content-color-inv !important;
}

a.melted {
  color: inherit;
}

.min-width-0 {
  min-width: 0;
}

.no-shadow {
  box-shadow: none !important;
}

.no-border-color {
  border-color: $gray-400 !important;
}

.no-fx {
  box-shadow: none !important;
  border-color: $gray-400 !important;
}

.static-size {
  font-size: 1.1rem !important;
}

.emphasis {
  padding: 0.25rem 0.5rem;
  color: white;
  background-color: rgba($basic-text-content-color, 0.9);
  border: 1px solid scale-color($basic-text-content-color, $lightness: -20%);
  border-radius: 0.25rem;

  &.primary {
    background-color: $brand-primary-color;
    border-color: scale-color($brand-primary-color, $lightness: -20%);
  }

  &.secondary {
    background-color: rgba($brand-secondary-color, 0.9);
    border-color: scale-color($brand-secondary-color, $lightness: -20%);
  }
}

.preview-key {
  padding: 0.5rem 0.7rem 0.3rem 0.7rem;
  border-radius: 0.7rem;
  white-space: nowrap;
  line-height: 2.2em;
  // font-size: 0.9em;
  // color: $white;
}

.scope-element {
  border: 2px solid $brand-light-color;
}

.scope-conversation {
  border: 2px solid $brand-primary-color;
}

.scope-bot {
  border: 2px solid $brand-secondary-color;
}

.scope-system {
  border: 2px solid $gray-500;
}

.scope-none {
  background-color: $gray-200;
  color: $gray-500;
}

.node-container {
  a,
  a:hover,
  a:visited,
  a:focus,
  a:active {
    color: inherit !important;
    text-decoration: underline !important;
  }
}

// -------------------------------------------------------

// Switch design -----------------------------------------------------------

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 4.616em;
  height: 2.616em;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 2em;
  width: 2em;
  left: 0.31em;
  top: calc(50% - 1em);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $brand-primary-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2em;
}

.slider.round:before {
  border-radius: 50%;
}

// -----------------------------------------------------------

// For custom colors ngx-bootstrap's datepicker
.datepicker-today {
  border: 1px solid $brand-primary-color;
  border-radius: 50%;
}

.datepicker-ideta-color {
  .bs-datepicker-head,
  .bs-datepicker-body table td span.selected,
  .theme-green .bs-datepicker-body table td.selected span,
  .theme-green .bs-datepicker-body table td span[class*='select-']:after,
  .theme-green .bs-datepicker-body table td[class*='select-'] span:after {
    background-color: $brand-primary-color;
  }

  .bs-datepicker-body table td.week span {
    color: $brand-primary-color;
  }
}

// Additional settings for ngx-bootstrap's dropdown
bs-dropdown-container {
  z-index: 1060;
}

// -----------------------------------------------------------
// For nav tabs
.badge-count {
  background: $brand-primary-color;
  color: #fff;
  font-weight: 600;
  padding: 0 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-tab {
  .nav-tab-content {
    .badge-count {
      background: $gray-400;
      margin-left: 5px;
    }
    img {
      height: 15px;
      filter: grayscale(1);
    }
  }

  &:not(.disabled) {
    .nav-tab-content {
      &.active,
      &:hover {
        .badge-count {
          background: $brand-primary-color;
        }
        img {
          filter: grayscale(0);
        }
      }
    }
  }
}

// -----------------------------------------------------------
// For a beautiful scrollbar
.ideta-scrollbar,
.ideta-scrollbar-h {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: 10px;
    &:hover {
      background: $gray-500;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &.scrollbar-small {
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
    }
  }
}

// -----------------------------------------------------------
// For customizing ngx-bootstrap's accordion
.panel.data-key-custom-class {
  padding: 1rem 0.5rem;
  border: none;
  background: transparent;
  box-shadow: inset 0 1px 0px #eee;
  border-radius: 0;

  .panel-heading {
    padding: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .panel-body {
    padding: 0;
    padding-top: 5px;
  }

  &.panel-enabled .panel-heading:hover {
    cursor: pointer;
  }

  &:hover {
    box-shadow: none;
  }
}
