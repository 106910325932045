/*
 * Fonts-related Sass parameters
 */
@import '_layout';

$content-font-family: 'Avenir Next LT Pro';

@mixin content-font-definition() {
  font-family: $content-font-family;
  font-weight: 400;
}

@mixin title-font-definition() {
  font-family: 'Avenir Next LT Pro';
  font-size: 1.3rem;
  font-weight: 400;
}

/*
 * Utilities
 */

@mixin text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Fluid font size
 */

@function strip-unit($value) {
  @return calc(#{$value} / (#{$value} * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/*
 * Overriding Bootstrap fonts
 */

$font-family-base: $content-font-family;

/*
* Display vars
*/
$common-max-font-size: 1.7em; //em to keep
$common-min-font-size: 1.4em; //em to keep
$response-font-size: 16px;
$generic-arrow-size: 4rem;

@mixin common-font-size() {
  font-size: $common-max-font-size;
  @include md-up {
    font-size: $common-min-font-size;
  }
}
