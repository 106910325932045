/*
 * Layout-related Sass parameters
 */

@use 'sass:math';

$html-font-size: 16px;
$header-height: 60px;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1440px;

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min
);

$grid-gutter-width: 20px;

//Browsers
@mixin target-metro {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// Small devices
@mixin sm-down {
  @media screen and (max-width: #{$screen-sm-min - 1}) {
    @content;
  }
}

@mixin sm-up {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md-down {
  @media screen and (max-width: #{$screen-md-min - 1}) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg-down {
  @media screen and (max-width: #{$screen-lg-min - 1}) {
    @content;
  }
}

@mixin lg-up {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl-down {
  @media screen and (max-width: #{$screen-xl-min - 1}) {
    @content;
  }
}

@mixin xl-up {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl-up {
  @media screen and (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

/* Global Size Control */

.ideta-size-control {
  @media screen and (max-width: 319px) {
    display: none !important;
  }
}

/* Pages containers */

.page-container {
  width: 100%;
  height: 100%;
}

$container-padding: 1.25rem;
$container-border-radius: 10px;

/* Sidebar */

$layout-animation-duration: 500ms;
$sidebar-width: 260px;
$sidebar-width-compact: 60px;

/*
 * Popovers
 */

$popover-max-width: none;
$popover-border-color: $gray-300;

/*
 * Modals
 */

// Messages

$modal-message-xs-max-height: 300px;
$modal-message-xs-width: 300px;

$modal-message-sm-max-height: 300px;
$modal-message-sm-width: 500px;

// Window

$modal-window-xs-max-height: 300px;
$modal-window-xs-width: 300px;

$modal-window-sm-max-height: 300px;
$modal-window-sm-width: 500px;

$modal-window-lg-max-height: 300px;
$modal-window-lg-width: 800px;

/*
* Display vars
*/

$web-header-height: 8vh;
$web-footer-height: 1.3 * $web-header-height;
$bot-icon-size: 3.5rem;
$message-margin-bottom: 0.3rem;
$preview-max-width: 50vh;
$min-node-height: 4.6rem;
$min-node-width: 6rem;
$node-border-style: solid;
$node-border-width: 0.25rem;
$general-overflow: auto;
$message-bubble-padding: 1.25rem;
$rich-text-min-height: calc(1.8125rem + 2px);

//px to rem conversion
@function rem($pxValue) {
  @return math.div($pxValue, $html-font-size) * 1rem;
}

@mixin banner-shape($marginTop) {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 100;

  @include md-down {
    background-image: unset !important;
    position: unset !important;
    margin-top: $marginTop;
  }
}

@mixin web-header() {
  @include banner-shape(none);
  height: $web-header-height;
  min-height: 50px;
  max-height: 70px;
}

@mixin web-footer() {
  @include banner-shape(auto);
  min-height: 70px;
  // height: $web-footer-height;
  // max-height: 100px;
  bottom: 0px;
  flex-direction: column;
  padding: 1.5rem;
  padding-top: 0;
}

@mixin node-border() {
  border-style: $node-border-style;
  border-width: $node-border-width;
  border-color: rgba(0, 0, 0, 0);
}

@mixin node-shape() {
  border-radius: 1.5rem;
  overflow: hidden;
  min-height: $min-node-height;
  min-width: $min-node-width;
  word-break: break-word;
  word-wrap: break-word;
  @include node-border();
}

@mixin node-shape-editable() {
  border-radius: 1.5rem;
  min-height: $min-node-height;
  min-width: $min-node-width;
  word-break: break-word;
  word-wrap: break-word;
  @include node-border();
}

@mixin parent-resize() {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include height-resize();
}

@mixin height-resize() {
  flex-grow: 1;
  height: 0;
  max-height: 100%;
}
